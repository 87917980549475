import React from 'react'
import styles from './styles.module.css';


const CompromisoSegundaVista = () => {
    return (
        <div className={styles.segundaVista_container}>
            <div className={styles.contenido}>
                <h1>SIN EDUCACIÓN NO HAY FUTURO, POR ESO APOYAMOS LAS ESCUELAS Y SU DESARROLLO TECNOLÓGICO.</h1>
                <h3>En Wedrim estamos trabajando constantemente para desarrollar entornos educativos que permitan que el aprendizaje y la tecnología puedan unirse para potenciar la creatividad y 
                    curiosidad de los niños, que puedan aprender acompañados de la mejor tecnología y los métodos de estudio más modernos. Nuestro compromiso ES con la educación HOY Y SIEMPRE.
                    Apoyando también, asociaciones, fundaciones y organizaciones educativas, para potenciarlas con nuestro apoyo</h3>
            </div>
        </div>
    )
}

export default CompromisoSegundaVista