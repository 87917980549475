import React from 'react'
import styles from './styles.module.css';


const SobreNosotrosTerceraVista = () => {
    return (
        <div className={styles.TerceraVista_container}>
            <div className={styles.contenido}>
                <h1>ACEPTANDO EL DESAFÍO DE CREAR UN MUNDO MEJOR</h1>
                <h3>Creemos que, a través de los desarrollos tecnológicos, podemos crear un mundo mejor para todos! ¡Aceptamos el reto, vamos por ello!</h3>
            </div>
        </div>
    )
}

export default SobreNosotrosTerceraVista