import React from 'react'
import Tarjeta from './Tarjeta'
import styles from './styles.module.css'
import { httpDigitalOceanStorage } from '../../api/server'


const tarjetas2 = [
    // {
    //     imagen:`${httpDigitalOceanStorage}logo_ogitech.png`,
    //     texto: 'OgiTech',
    // },
    {
        imagen:`${httpDigitalOceanStorage}avanzada7.png`,
        texto: 'Avanzada 7',
    },
    {
        imagen:`${httpDigitalOceanStorage}cse_logo.png`,
        texto: 'CSE',
    },
    {
        imagen:`${httpDigitalOceanStorage}pyxis.png`,
        texto: 'Pyxis',
    },
    {
        imagen:`${httpDigitalOceanStorage}logo_1.png`,
        texto: 'Nuevas Tecnologías para la Educación',
    },

]

const AlianzasEstrategicas = (props) => {


    return (
        <div className={styles.alianzas_container}>
            <div className={styles.contenido}>
                <h1>ALIANZAS ESTRATÉGICAS.</h1>
                <h3>Generamos una red de alianzas estratégicas, para potenciarnos mutuamente, nuestros negocios y los de nuestros clientes.</h3>
                <div className={styles.container_tarjetas}>
                <span className={styles.fila}>{tarjetas2.map((tarjeta, idx) => <Tarjeta key={`ct-${idx}`} imagen={tarjeta.imagen} texto={tarjeta.texto} />)}</span>
                </div>
            </div>
        </div>
    )

}

export default AlianzasEstrategicas