import React from "react";
import { Modal, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { apiDelete } from "../../api/index";

const useStyles = makeStyles((theme) => ({
    subtitulo: {
        padding: theme.spacing(1, 1),
    },

    titulo: {
        padding: theme.spacing(1, 1),
    },
    campos: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: theme.spacing(2, 5),
        textAlign: "center",
    },

    campos_preguntas: {
        alignItems: "center",
    },

    eliminar_container: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`,
        width: 500,
        padding: theme.spacing(5, 5),
        backgroundColor: "#EBEBEB",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            width: 500,
            maxHeight: "80%",
            overflowY: "auto",
        },
        [theme.breakpoints.down("sm")]: {
            width: 350,
            maxHeight: "80%",
            overflowY: "auto",
        },
    },
}));

const ModalEliminar = (props) => {

    const styles = useStyles();
    const open = props.open;
    const { id } = props


    const handleClose = () => {
        props.closeFromParent()
    }



    // console.log(id) //props id, OK

    /*Eliminar*/

    const handleEliminar = () => {

        apiDelete("http://localhost:3001/contacto", {
            id: id
        })
            .then((r) => {
                console.log(r)
                if (r.status === "OK") {
                    console.log("todo ok");
                } else {
                    console.log(r.data);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };
    
    return (
        <Modal open={open} onClose={handleClose}>
            <div className={styles.eliminar_container}>
                <h3 className={styles.titulo}>Eliminar</h3>
                <h4 className={styles.subtitulo}>
                    ¿Está seguro que desea eliminar?
                </h4>
                <div className={styles.botones}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleEliminar(id)}
                        >
                        Confirmar
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalEliminar;
