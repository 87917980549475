import React from 'react'
import styles from './styles.module.css';


const EducativasSegundaVista = () => {
    return (
        <div className={styles.segundaVista_container}>
            <div className={styles.contenido}>
                <h1>PLATAFORMA SINCRÓNICA, ¡PENSADA PARA EMULAR EL AULA FÍSICA DE MANERA VIRTUAL, INTERACTIVA Y DIDÁCTICA!.</h1>
                <h3>La plataforma para la educación del mañana!</h3>
            </div>
        </div>
    )
}

export default EducativasSegundaVista