import React from 'react'
import Tarjeta from './Tarjeta'
import styles from './styles.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom"

const tarjetas = [
	{
		imagen: 'Imagen Tarjeta Novedad  1',
		tema_nota: 'tema_nota',
		titulo: <Link>Titulo Novedad 1</Link>,
		id: '1',
		autor: 'Nombre Autor'
	},
	{
		imagen: 'Imagen Tarjeta Novedad 2 ',
		tema_nota: 'tema_nota',
		titulo: <Link>Titulo Novedad 2</Link>,
		id: '2',
		autor: 'Nombre Autor'
	},
	{
		imagen: 'Imagen Tarjeta Novedad 3',
		tema_nota: 'tema_nota',
		titulo: <Link>Titulo Novedad 3</Link>,
		id: '3',
		autor: 'Nombre Autor'
	},
	{
		imagen: 'Imagen Tarjeta Novedad 4',
		tema_nota: 'tema_nota',
		titulo: <Link>Titulo Novedad 4</Link>,
		id: '4',
		autor: 'Nombre Autor'
	}
]

const NovedadesSegundaVista = props => {

	return (
		<div className={styles.cards}>
			<div className={styles.busqueda_notas}>
				<span className={styles.campo_boton}>
					<input type="text" placeholder='Buscar título...'></input>
				</span>
				<button className={styles.ver_notas}>Listar todas las notas (4)</button>
			</div>
			<div className={styles.container_tarjetas}>
				{tarjetas.map(
					(tarjeta, idx) =>
						<div className={styles.tarjeta_curso}>
							<Tarjeta
								key={`ct-${idx}`}
								tema_nota={tarjeta.tema_nota}
								imagen={tarjeta.imagen}
								titulo={tarjeta.titulo}
								autor={tarjeta.autor}
							/>
						</div>
				)}
			</div>
			<div className={styles.ver_mas}>
				<button className={styles.ver_todos}>
					<FontAwesomeIcon className={styles.play_button} icon={faPlusCircle} />
					<h2>Cargar más notas</h2>
				</button>
			</div>
		</div>
	)
}

export default NovedadesSegundaVista
