import React from 'react'
import styles from './styles.module.css'
import { useHistory } from 'react-router-dom'

const PantallaAdmin = () => {

    const history = useHistory()

    return (
        <div className={styles.botonesContainer}>
            <h2>Administrador WeDrim</h2>
            {/* <button onClick={() => history.push('/formcargausuario')}>Administrador de Usuarios</button> */}
            <button onClick={() => history.push('/formblog')}>Administrador de Blog</button>
            <button onClick={() => history.push('/admincv')}>Administrador de CV</button>
            <button onClick={() => history.push('/consultas')}>Administrador de Consultas</button>
        </div>
    )
}

export default PantallaAdmin

