import React, { useState, useEffect } from 'react'
import { Modal, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { apiPost, apiPut } from '../../api/index'
import { apiGet } from '../../api'
import { urlBlog } from '../../api/urls'
import Editor from '../Admin/Editor'


const useStyles = makeStyles((theme) => ({

    titulo: {
        textAlign: 'center',
    },
    guardar: {
        fontFamily: 'FreeSans',
        fontSize: '18px',
        padding: theme.spacing(1, 2),
        backgroundColor: '#6699CC',
        borderRadius: '10px',
        color: '#ffffff',
        border: 'none',
        cursor: 'pointer',
        marginLeft: '5%',
        marginTop: '5%',

    },
    campos: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: theme.spacing(2, 5),
        textAlign: 'center',
    },
    blog_container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        width: 900,
        height: 900,
        padding: theme.spacing(3, 5),
        backgroundColor: '#EBEBEB',
        overflowY: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: 500,
            maxHeight: '80%',
            overflowY: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            width: 350,
            maxHeight: '80%',
            overflowY: 'auto',
        },


    }

}))

const ModalFormBlog = (props) => {

    const [blogPost, setBlogPost] = useState({})
    const styles = useStyles();
    const { open, prevBlog, handleCloseForm } = props;

    const handleClose = () => {
        setBlogPost({})
        handleCloseForm()
    }


    useEffect(() => {
        if (prevBlog?.id) {
            setBlogPost({
                titulo: prevBlog.titulo, subtitulo: prevBlog.subtitulo, autor: prevBlog.autor.id,
                texto: prevBlog.texto, titulo_seccion_tarjeta: prevBlog.titulo_seccion_tarjeta,
                subtitulo_seccion_tarjeta: prevBlog.subtitulo_seccion_tarjeta
            })
        }

    }, [prevBlog])




    /*Subir Preguntas*/

    // const handleSubir = () => {
    //     if (prevBlog?.id) {
    //         apiPut(urlBlog, {
    //             id: prevBlog.id,
    //             ...blogPost

    //         })
    //             .then((r) => {
    //                 if (r.status === "OK") {
    //                     console.log("todo ok");
    //                     handleClose()
    //                 } else {
    //                     alert('error al guardar el post')
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.error(err);
    //                 console.log('salio por el error')
    //             });
    //     } else {
    //         apiPost(urlBlog, {
    //             ...blogPost
    //         })
    //             .then((r) => {
    //                 if (r.status === "OK") {
    //                     console.log("todo ok");
    //                     handleClose()

    //                 } else {
    //                     alert('error al guardar el post')
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.error(err);
    //             });
    //     }
    // };

    return (
        <Modal open={open} onClose={handleClose}>
            <div className={styles.blog_container}>
                <h3 className={styles.titulo}>Blog</h3>
                <form className={styles.campos_blog}>
                    <label>Titulo del Blog</label>
                    <input
                        type="text"
                        id="titulo_blog"
                        placeholder='Titulo Blog'
                        value={blogPost.titulo}
                        onChange={e => {
                            console.log(e.target.value, blogPost);
                            setBlogPost({
                                ...blogPost, titulo: e.target.value
                            })
                        }}
                        className={styles.campos} />
                    <label>Subtitulo del Blog</label>
                    <input
                        type="text"
                        id="subtitulo_blog"
                        placeholder='Subtitulo Blog'
                        value={blogPost.subtitulo}
                        onChange={e => {
                            console.log(e.target.value, blogPost);
                            setBlogPost({
                                ...blogPost, subtitulo: e.target.value
                            })
                        }}
                        className={styles.campos} />
                    <label>Texto del Blog</label>
                    <Editor texto={blogPost.texto} onChange={(texto) => setBlogPost(ps => ({ ...ps, texto }))} />
                </form>
                <button className={styles.guardar} >Guardar</button>
            </div>
        </Modal>
    )
}

export default ModalFormBlog