import React, { useState } from 'react'
import styles from './styles.module.css';
import { Input, Button, IconButton, Modal } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons'
import { apiPost } from '../../api'
import { urlCurriculum } from '../../api/urls'

const TrabajoEnWeDrim = () => {

    const initialState = {
        firstname: '',
        lastname: '',
        mail: '',
    }

    const [datos, setDatos] = useState(initialState)
    const [documento, setDocumento] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)

    const openSelector = () => {
        document.getElementById('selcv').click()
    }

    const encodeBase64 = (file, onLoadCallback) => {
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = onLoadCallback
    }

    const validateSize = (e, size) => {
        return e.size < size
    }

    const tamMax = 1


    const handleInputChange = (e) => {
        // console.log(e.target.value)
        setDatos({
            ...datos,
            [e.target.name]: e.target.value
        })
    }

    const handlePost = () => {
        console.log(datos)
        apiPost( urlCurriculum, {...datos, documento }).then(r => {
            if (r.status === 'OK') {
                alert('curriculum enviado')
            } else {
                alert('ERROR al enviar curriculum')
            }
        })
    }


    return (
        <div className={styles.trabajo_wedrim_container}>
            <div className={styles.contenido}>
                <h3>En WeDrim estamos buscando siempre grandes talentos! Si crees que eres uno, no dudes en enviarnos tu CV:</h3>
                <form className={styles.form_contacto} id="formulario">
                    <input
                        name="firstname"
                        placeholder="Nombre"
                        onChange={handleInputChange}
                        value={datos.firstname}
                    />
                    <input
                        name="lastname"
                        placeholder="Apellido"
                        onChange={handleInputChange}
                        value={datos.lastname}
                    />
                    <input
                        name="mail"
                        placeholder="Email"
                        onChange={handleInputChange}
                        value={datos.mail}
                    />
                </form>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
                    <Button className={styles.button_cv} onClick={openSelector}>
                        Subir Curriculum Vitae
                        <input
                            accept='.pdf, .doc, .docx, .odt'
                            style={{ display: 'none' }}
                            id='selcv'
                            type='file'
                            onChange={({ target: { files } }) => {
                                if (files[0]) {
                                    const file = files[0]
                                    if (validateSize(files[0], tamMax * 1024 * 1024)) {
                                        encodeBase64(file, f =>
                                            setDocumento(ps => ({
                                                ...ps, archivo: file.name, encoded: f.target.result
                                            }))
                                        )
                                    } else alert('El tamaño de la imagen no puede superar los ' + tamMax + ' MB')
                                }
                            }}
                        />
                    </Button>
                    <p style={{ fontFamily: 'Montserrat', fontSize: '15px', color: '#fff' }} >{documento.archivo || '-Ningun archivo seleccionado-'}</p>
                    {documento.encoded ? <IconButton onClick={() => setDocumento(ps => ({ ...ps, encoded: undefined, archivo: undefined }))}>
                        <CloseRounded style={{ fontSize: '18px', color: '#fff' }} />
                    </IconButton> : null}
                </div>
                <span style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                    <button className={styles.btn_enviar_cv}
                        onClick={() => handlePost()}
                        disabled={isSubmitting} >{isSubmitting ? 'Enviando' : 'Enviar'}
                    </button>
                </span>
            </div>
        </div>
    )
}

export default TrabajoEnWeDrim