import React, { useEffect, useState } from 'react'
import Tarjeta from './Tarjeta'
import styles from './styles.module.css'
import { httpDigitalOceanStorage } from '../../api/server'

const tarjetas = {

    firstRow: [
        {
            imagen: `${httpDigitalOceanStorage}pyxis_redimensionada.png`,
            texto: 'Pyxies',
        },
        // {
        //     imagen: `${httpDigitalOceanStorage}logo_ogitech1.png`,
        //     texto: 'OgiTech',
        // },
        {
            imagen: `${httpDigitalOceanStorage}cse_logo_(2)(1).png`,
            texto: 'CSE',
        },
        {
            imagen: `${httpDigitalOceanStorage}logo_1.png`,
            texto: 'Nuevas Tecnologías para la Educación',
        },
        {
            imagen: `${httpDigitalOceanStorage}logo-ba-2020-removebg-preview(1).png`,
            texto: 'Gobierno de La Provincia de Buenos Aires',
        },
        {
            imagen: `${httpDigitalOceanStorage}logo-ledesma-1.png`,
            texto: 'LEDESMA',
        },
    ],

    secondRow: [
        {
            imagen: `${httpDigitalOceanStorage}Orden_de_Frailes_Menores1.png`,
            texto: 'Orden Franciscana Peru',
        },
        {
            imagen: `${httpDigitalOceanStorage}conciencia-removebg_recortada.png`,
            texto: 'Asociación Conciencia',
        },
        {
            imagen: `${httpDigitalOceanStorage}banner-web-041.png`,
            texto: 'Revista Colegio',
        },
        {
            imagen: `${httpDigitalOceanStorage}fundacion1.png`,
            texto: 'Fundación PAD',
        },
        {
            imagen: `${httpDigitalOceanStorage}mz-logo-redimensionada.png`,
            texto: 'Martin Zubeldia',
        },
                {
            imagen: `${httpDigitalOceanStorage}LogoScrum_Blanco_redimensionada.png`,
            texto: 'SCRUM',
        },
    ]

}


const Clientes = (props) => {


    const [currentWidth, setCurrentWidth] = useState(window.innerWidth)

    const handleResize = () => {
        setCurrentWidth(window.innerWidth)
    }


    useEffect(() => {
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    return (
        <div className={styles.clientes_container}>
            <div className={styles.contenido}>
                <h1>CLIENTES.</h1>
                <h3>Algunos de nuestros más recientes clientes.</h3>
                {currentWidth > 600
                 ? <div className={styles.container_tarjetas}>
                    <ul className={styles.marquee_content_left}>
                        <li><span className={styles.fila}>{[...tarjetas.firstRow, ...tarjetas.firstRow, ...tarjetas.firstRow].map((tarjeta, idx) => <Tarjeta key={`ct-${idx}`} imagen={tarjeta.imagen} texto={tarjeta.texto} />)}</span></li>
                    </ul>
                    <ul className={styles.marquee_content_right}>
                        <li><span className={styles.fila}>{[...tarjetas.secondRow, ...tarjetas.secondRow, ...tarjetas.secondRow].map((tarjeta, idx) => <Tarjeta key={`ct-${idx}`} imagen={tarjeta.imagen} texto={tarjeta.texto} />)}</span></li>

                    </ul>
                </div> :
                    <div className={styles.container_tarjetas_movil}>
                        <span className={styles.fila}>{tarjetas.firstRow.map((tarjeta, idx) => <Tarjeta key={`ct-${idx}`} imagen={tarjeta.imagen} texto={tarjeta.texto} />)}</span>
                        <span className={styles.fila}>{tarjetas.secondRow.map((tarjeta, idx) => <Tarjeta key={`ct-${idx}`} imagen={tarjeta.imagen} texto={tarjeta.texto} />)}</span>
                    </div>}
            </div>
        </div>
    )

}

export default Clientes