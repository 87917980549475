import React from 'react'
import styles from './styles.module.css';
import { Link } from "react-router-dom"


const QuienesSomos = () => {
    return (
        <div className={styles.somos_container}>
             <video className={styles.video_principal} alt="" autoPlay loop muted>
                <source src={`https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/wedrim_comic.mp4`} type="video/mp4"/>
            </video>
            <div className={styles.contenido}>
                <h1>SOMOS UNA COMPAÑÍA DE DESARROLLO DE SOFTWARE Y TECNOLOGÍAS, APASIONADA POR LO QUE HACE Y COMPROMETIDA CON EL FUTURO.</h1>
                <Link to='/sobrenosotros' className={styles.sobre_nosotros}>Sobre Nosotros</Link>
            </div>
        </div>
    )
}

export default QuienesSomos