import React, { useEffect, useState } from 'react'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'


const Editor = (props) => {

    const {texto, onChange} = props

    const editorSettings = {
        toolbar: [
            'heading', '|',
            'undo',
            'redo',
            '|',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            'bold',
            'italic',
            'strikethrough',
            'underline',
            'link',
            'imageUpload',
            'blockQuote',
            'alignment',
            'numberedList',
            'bulletedList',
            '|',
        ],

        initialData : texto
    }

    const onChangeEditor = () => {
        const data = window.editor.getData()
        onChange(data)
    }

    useEffect(() => {
        DecoupledEditor.create(document.querySelector('.document-editor__editable'), editorSettings)
            .then(editor => {
                const toolbarContainer = document.querySelector('.document-editor__toolbar')

                toolbarContainer.appendChild(editor.ui.view.toolbar.element)
                window.editor = editor
                editor.model.document.on('change:data', () => {
                    onChangeEditor()
                })
            })
            .catch(err => {
                console.error(err)
            })
    }, [])

    console.log(texto)

    return (
            <div className='document-editor'>
                <div className='document-editor__toolbar'/>
                <div className='document-editor__editable-container' style={{ width: '100%' }} >
                    <div className='document-editor__editable' style={{ minHeight: '50vh', width: '100%' }}>

                    </div>
                </div>
            </div>
    )
}

export default Editor