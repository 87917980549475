import React, { useState } from 'react'
import styles from './styles.module.css'
import { apiPost } from '../../api'
import { urlUsers } from '../../api/urls'




const Formulario = () => {

	const [ { username, password,  loading }, setState ] = useState({ username: '', password: '', loading: false })


	const onLogin = () => {
        setState(ps => ({ ...ps, loading: true })) 
        // El loading, si lo queres usar, cuando esta en true esta esperando el resultado del fetch, podes mostrar un spinner o deshabilitar un boton mientras carga
		apiPost( urlUsers, { username, password })
			.then(r => {
				if (r.status === 'UNAUTHORIZED') {
					// Aca deberia tirar un mensaje que diga "Usuario o contraseña incorrecto"
					setState(ps => ({ ...ps, loading: false }))
                } else if (r.status === 'OK') {
                    localStorage.setItem('auth-token', r.token)
                    window.location.href = '/PantallaAdmin'   //Acá me redirecciona al admin
				} else {
					// Aca deberia tirar un mensaje que diga "Algo salió mal" o "Ocurrio un error"
					setState(ps => ({ ...ps, loading: false }))
				}
			})
			.catch(e => console.log(e))
	}


	const onkeyPress = key => {
		if (key.which === 13 || key.keyCode === 13) {
			onLogin()
			return false // returning false will prevent the event from bubbling up.
		} else return true
	}


	return (
		<div className={styles.form_container}>
			<div className={styles.formulario}>
				<div className={styles.campos_formulario}>
					<h2 className={styles.titulo_form}>
						{' '}
						Administrador - Login
					</h2>
					<form action=''>
						<input
							type='text'
							id='name'
							name='username'
							value={username}
							placeholder='Usuario'
							onKeyPress={onkeyPress}
							onChange={({ target: { name, value } }) => setState(ps => ({ ...ps, [name]: value }))}
						/>
						<input
							type='password'
							id='password'
							name='password'
							value={password}
							placeholder='Contraseña'
							onKeyPress={onkeyPress}
							onChange={({ target: { name, value } }) => setState(ps => ({ ...ps, [name]: value }))}
						/>
						<span className={styles.form_buttons}>
							<button
								className={styles.inicio_sesion}
								onClick={onLogin}
                                disabled={loading}
							>
								Ingresar
							</button> 
						</span>
					</form>
				</div>
			</div>
		</div>
	)
}

export default Formulario
