import React, { useState } from 'react'
import styles from './styles.module.css';
import { urlContact } from '../../api/urls'


const Contacto = () => {

    const initialState = {
        name: '',
        lastname: '',
        from: '',
        surname: '',
        subject: '',
        text: ''
    }

    const [datos, setDatos] = useState(initialState)

    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleInputChange = (e) => {
        // console.log(e.target.value)
        setDatos({
            ...datos,
            [e.target.name]: e.target.value
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        console.log(datos)
        setIsSubmitting(true)
        fetch("http://localhost:3001/contacto",
            {
                method: 'POST',
                body: JSON.stringify(datos),
                headers: { 'Content-Type': 'application/json' }
            }).then(r => r.json())
            .then(r => {
                console.log(r)
                if (r.status === 'OK') {
                    alert('Consulta Enviada')
                    setDatos(initialState)
                    setIsSubmitting(false)
                } else {
                    alert('ERROR al enviar consulta')
                    setIsSubmitting(false)
                }
            })
            .catch(err => {
                console.log(err)

            })
    }

    return (
        <div className={styles.contacto_container}>
            <div className={styles.contenido}>
                <span className={styles.texto_contacto}>
                    <h1>Contacto</h1>
                    <h3>¡No dude en escribirnos! WeDrim está para servirle y darle el mejor asesoramiento.</h3>
                </span>
                <form className={styles.form_contacto} id="formulario" onSubmit={handleSubmit}>
                    <input
                        name="name"
                        placeholder="Nombre"
                        onChange={handleInputChange}
                        value={datos.name}
                    />
                    <input
                        name="lastname"
                        placeholder="Apellido"
                        onChange={handleInputChange}
                        value={datos.lastname}
                    />
                    <input
                        name="from"
                        placeholder="Email"
                        onChange={handleInputChange}
                        value={datos.from}
                    />
                    <input
                        name="subject"
                        placeholder="Asunto"
                        onChange={handleInputChange}
                        value={datos.subject}
                    />
                    <textarea
                        name="text"
                        placeholder="Mensaje"
                        onChange={handleInputChange}
                        value={datos.text}
                    />
                    <button id="boton" type="submit" disabled={isSubmitting} >{isSubmitting ? 'Enviando' : 'Enviar'}</button>
                </form>
            </div>
        </div>
    )
}

export default Contacto