import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { apiGet } from '../../api'
// import { urlBlog } from '../../api/urls'
import ModalFormBlog from '../Modales/ModalFormBlog'
import ModalEliminar from '../Modales/ModalEliminar'


const FormBlog = (props) => {

    const [verNotas, setVerNotas] = useState(false)
    const [modal, setModal] = useState(false)
    const [busqueda, setBusqueda] = useState([])
    const [posts, setPosts] = useState([])
    const [eliminar, setEliminar] = useState(false)
    const [idEliminar, setIdEliminar] = useState(undefined)
    const [blogToEdit, setBlogToEdit] = useState(undefined)

    const handleOpen = () => {
        setModal(true)
    }

    const handleEditar = (item) => {
        handleOpen()
        setBlogToEdit(item)
    }

    const handleCloseForm = () => {
        setModal(false)
        setBlogToEdit(undefined)
        // getPosts()
    }

    const handleCloseEliminar = (id) => {
        setEliminar(true)
        setIdEliminar(id)
    }

    const handleCancelar = () => {
        setEliminar(false)
        // getPosts()
    }

    const handleVer = () => {
        setVerNotas(true)

    }

    const handleOcultar = () => {
        setVerNotas(false)

    }

    // const getPosts = () => {
    //     apiGet(urlBlog).then(respuesta => {
    //         if (respuesta.status === 'OK')
    //             setPosts(respuesta.data)
    //         else
    //             console.error("Error al recibir data")
    //     }).catch(err => console.error(err))
    // }

    // useEffect(() => {
    //     getPosts()
    // }, [])

    let postsFiltrados = []
    if (busqueda) postsFiltrados = posts.filter(item => {
        return item.titulo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(busqueda)
    })
    else postsFiltrados = posts

    return (
        <div className={styles.form_carga_container}>
            <div className={styles.indice_blog}>
                <h1 className={styles.admin_titulo}>Administracion del blog</h1>
                <div className={styles.titulo_campos}>
                    <h4 className={styles.titulo_indice}>
                        Índice de Notas
                    </h4>
                    <span className={styles.campo_boton}>
                        <input type="text" placeholder='Buscar título...'onChange={e => setBusqueda(e.target.value)} />
                    </span>
                    <a className={styles.ver_notas} onClick={handleVer}>
                        Listar todas las notas ({postsFiltrados.length})
                    </a>
                    {verNotas ? <ul>
                        {postsFiltrados.map(item => (<li key={item.id}>{item.titulo}
                            <FontAwesomeIcon
                                className={styles.trash_icon}
                                icon={faTrash}
                                onClick={() => handleCloseEliminar(item.id)}
                            />
                            <FontAwesomeIcon
                                className={styles.pen_icon}
                                icon={faPen}
                                onClick={() => handleEditar(item)}
                            />
                        </li>))}
                        <a className={styles.cerrar_notas} onClick={() => handleOcultar()}>Ver menos</a>
                    </ul> : null}
                    <button className={styles.nuevo_btn} onClick={handleOpen} >Nueva nota de blog</button>
                    <ModalFormBlog closeFromParent={handleCloseForm} open={modal} prevBlog={blogToEdit} handleCloseForm={handleCloseForm} />
                    <ModalEliminar closeFromParent={handleCancelar} open={eliminar} id={idEliminar} handleCloseEliminar={handleCloseEliminar} />
                </div>
            </div>
        </div>
    )
}

export default FormBlog
