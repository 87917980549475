import React from 'react'
import styles from './styles.module.css'

const Tarjeta = (props) => {

    const { imagen, texto } = props;
    return (
        <div className={styles.tarjeta}>
            <img src={imagen} alt="" />
            <div className={styles.texto}>
                <p>{texto}</p>
            </div>
        </div>
    )
}

export default Tarjeta
