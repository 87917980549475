import React from 'react'
import styles from './styles.module.css'

const Tarjeta = (props) => {

    const { titulo, texto } = props;
    return (
        <div className={styles.tarjeta}>
            <div className={styles.texto}>
                <h2>{titulo}</h2>
                <p>{texto}</p>
            </div>
        </div>
    )
}

export default Tarjeta
