import { wedrimBackend, fileServer } from "./server"



export const urlUsers = `${wedrimBackend}login`
export const urlContact = `${wedrimBackend}contacto`
export const urlCurriculum = `${wedrimBackend}curriculum`

export const urlFileCurriculum = `${fileServer}`

export const facebookUrl = 'https://www.facebook.com/wedrim.it'
export const instagramUrl = 'https://www.instagram.com/wedrim.it'