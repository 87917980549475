import React, { useState } from 'react'
import styles from './styles.module.css'
import { Link } from 'react-router-dom'
import { httpDigitalOceanStorage } from '../../api/server'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faBalanceScaleLeft, faBars, faEnvelope, faGlobe, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { facebookUrl, instagramUrl } from '../../api/urls'
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next'


const Navbar = (props) => {

    const [shown, setShown] = useState(false)
    const [desktop, setDesktop] = useState(false)
    const [t, i18n] = useTranslation("navbar")


    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSeleccion = () => {
        setAnchorEl(null);
    };

    {/*Dos estados por desplegable desktop y movil     */ }

    return (
        <nav className={styles.navbar}>
            <Link to='/'> <img src={`${httpDigitalOceanStorage}WeDrim_logo_recortado_blanco.png`} alt="" /></Link>
            <div className={styles.navbar_btn}>
                <ul >
                    <li className={styles.lista_servicios}>
                        <a className={styles.servicios} >{t("navbar.services")} <FontAwesomeIcon icon={faAngleDown} className={styles.down_arrow} /></a>
                        <ul className={styles.servicios_dropdown}>
                            <li className={styles.menu_dropdown}><Link to='/nuestrosservicios'>Generales</Link></li>
                            <li className={styles.menu_dropdown}><Link to='/serviciospymes'>PYMES</Link></li>
                            <li className={styles.menu_dropdown}><Link to='/servicioscorporaciones'>Grandes Corporaciones</Link></li>
                            <li className={styles.menu_dropdown}><Link to='/institucioneseducativas'>Instituciones Educativas </Link></li>
                        </ul>
                    </li>


                    <li><Link to='/nuestrocompromiso'>{t("navbar.our_commitment")}</Link></li>
                    <li><Link to='/elegirnos'>{t("navbar.choose_us")}</Link></li>
                    <li className={styles.lista_contactos} >
                        <a className={styles.contactos}>{t("navbar.contact_us")}<FontAwesomeIcon icon={faAngleDown} className={styles.down_arrow} /></a>
                        <ul className={styles.contactos_dropdown}>
                            <li className={styles.menu_dropdown}><Link to='/trabajeenwedrim'>Trabaje en WeDrim</Link></li>
                            <li className={styles.menu_dropdown}><Link to='/contacto'>Contacto</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Button className={styles.idiomas} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                            <FontAwesomeIcon icon={faGlobe} className={styles.down_arrow} />
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleSeleccion}>
                            <MenuItem>Próximamente</MenuItem>
                            {/* <MenuItem onClick={handleSeleccion, () => i18n.changeLanguage("es")}>Español</MenuItem> */}
                            {/* <MenuItem onClick={handleSeleccion, () => i18n.changeLanguage("en")}>English</MenuItem> */}
                        </Menu>
                    </li>

                    <FontAwesomeIcon className={styles.desplegable_visible} icon={faBars} onClick={() => setDesktop(true)} />
                </ul>
                <div style={{ display: desktop ? 'flex' : 'none', flexDirection: 'column' }} className={styles.menu_navegacion_desktop}>
                    <span style={{ position: 'absolute', right: '10px', top: '10px', color: 'black', cursor: 'pointer' }}
                        onClick={() => setDesktop(false)}>

                        <FontAwesomeIcon icon={faTimes} style={{ fontSize: '30px' }} />
                    </span>
                    <ul onClick={() => setDesktop(false)} >
                        <li><Link to='/'>Home</Link></li>
                        <li><Link to='/sobrenosotros'>Sobre WeDrim</Link></li>
                        <li><Link to='/nuestrosservicios'>Servicios Generales</Link></li>
                        <li><Link to='/nuestrocompromiso'>Compromiso</Link></li>
                        <li><Link to='/elegirnos'>Elíjanos</Link></li>
                        <li><Link to='/tecnologías'>Tecnologías</Link></li>
                        {/* <li><Link to='/alianzas'>Alianzas Estratégicas</Link></li> */}
                        <li><Link to='/clientes'>Clientes</Link></li>
                        <li><Link to='/productos'>Productos</Link></li>
                        <li><Link to='/trabajeenwedrim'>Trabaje en WeDrim</Link></li>
                        <li><Link to='/serviciospymes'>Servicios especiales para Pymes</Link></li>
                        <li><Link to='/servicioscorporaciones'>Servicios especiales para Grandes Corporaciones</Link></li>
                        <li><Link to='/institucioneseducativas'>Servicios para Instituciones Educativas</Link></li>
                        {/* <li><Link to='/novedades'>Novedades</Link></li> */}
                        {/* <li><a href='#contacto'> Social</a></li> */}
                        <li><FontAwesomeIcon icon={faEnvelope} style={{ color: 'black', marginRight: '5px' }} /><Link to='/contacto'>Contacto</Link></li>
                        {/* <li><FontAwesomeIcon icon={faBalanceScaleLeft} style={{ color: 'black' }} /><Link to='/legales'> Legales</Link></li> */}
                    </ul>

                    <div className={styles.iconos_redes}>
                        <div className={styles.contenido}>
                            <FontAwesomeIcon icon={faFacebook} className={styles.icono_redes} onClick={() => window.open(facebookUrl, '_blank')} />
                            <FontAwesomeIcon icon={faInstagram} className={styles.icono_redes} onClick={() => window.open(instagramUrl, '_blank')} />
                            {/* <img style={{width: '35px', cursor: 'pointer'}} src ="https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/ariba-negro-recortada.png"/> */}
                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.desplegable_btn}>
                <FontAwesomeIcon className={styles.desplegable_icon} icon={faBars} onClick={() => setShown(true)} />
            </div>
            <div style={{ display: shown ? 'flex' : 'none' }} className={styles.menu_navegacion}>
                <span style={{ position: 'fixed', right: '10px', top: '10px', color: 'black' }}
                    onClick={() => setShown(false)}>

                    <FontAwesomeIcon icon={faTimes} style={{ fontSize: '30px' }} />
                </span>
                <ul>
                    <li onClick={() => setShown(false)} ><Link to='/'>Home</Link></li>
                    <li onClick={() => setShown(false)} ><Link to='/sobrenosotros'>Sobre WeDrim</Link></li>
                    <li onClick={() => setShown(false)} ><Link to='/nuestrosservicios'>Servicios</Link></li>
                    <li onClick={() => setShown(false)} ><Link to='/nuestrocompromiso'>Compromiso</Link></li>
                    <li onClick={() => setShown(false)} ><Link to='/elegirnos'>Por qué Elegirnos</Link></li>
                    <li onClick={() => setShown(false)} ><Link to='/tecnologías'>Tecnologías</Link></li>
                    {/* <li onClick={() => setShown(false)} ><Link to='/alianzas'>Alianzas Estratégicas</Link></li> */}
                    <li onClick={() => setShown(false)} ><Link to='/clientes'>Clientes</Link></li>
                    <li onClick={() => setShown(false)} ><Link to='/productos'>Productos</Link></li>
                    <li onClick={() => setShown(false)} ><Link to='/trabajeenwedrim'>Trabajo en WeDrim</Link></li>
                    <li onClick={() => setShown(false)} ><Link to='/serviciospymes'>Servicios especiales para Pymes</Link></li>
                    <li onClick={() => setShown(false)} ><Link to='/servicioscorporaciones'>Servicios especiales para Grandes Corporaciones</Link></li>
                    <li onClick={() => setShown(false)} ><Link to='/institucioneseducativas'>Servicios para Instituciones Educativas</Link></li>
                    {/* <li onClick={() => setShown(false)} ><Link to='/novedades'>Novedades</Link></li> */}
                    {/* <li  onClick={() => setShown(false)} ><a href='#contacto'> Social</a></li> */}
                    <li onClick={() => setShown(false)} ><FontAwesomeIcon icon={faEnvelope} style={{ color: 'black', marginRight: '2%' }} /><Link to='/contacto'>Contacto</Link></li>
                    {/* <li  onClick={() => setShown(false)} ><FontAwesomeIcon icon={faBalanceScaleLeft} style={{ color: 'black' }} /><Link to='/legales'> Legales</Link></li> */}
                    <li><Button className={styles.idiomas} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                        <FontAwesomeIcon icon={faGlobe} className={styles.down_arrow} />
                    </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleSeleccion}>
                            <MenuItem>Próximamente</MenuItem>
                            {/* <MenuItem onClick={handleSeleccion, () => i18n.changeLanguage("es")}>Español</MenuItem> */}
                            {/* <MenuItem onClick={handleSeleccion, () => i18n.changeLanguage("en")}>English</MenuItem> */}
                        </Menu>
                    </li>
                </ul>

                <div className={styles.iconos_redes}>
                    <div className={styles.contenido}>
                        <FontAwesomeIcon icon={faFacebook} className={styles.icono_redes} onClick={() => window.open(facebookUrl, '_blank')} />
                        <FontAwesomeIcon icon={faInstagram} className={styles.icono_redes} onClick={() => window.open(instagramUrl, '_blank')} />
                        {/* <FontAwesomeIcon icon={faLinkedin} className={styles.icono_redes} /> */}
                        {/* <img style={{width: '35px', cursor: 'pointer'}} src ="https://wedrim.sfo2.cdn.digitaloceanspaces.com/media/ariba-negro-recortada.png"/> */}
                    </div>
                </div>



            </div>
        </nav>
    )
}

export default Navbar
