import React from 'react'
import styles from './styles.module.css';


const ElegirnosSegundaVista = () => {
    return (
        <div className={styles.segundaVista_container}>
            <div className={styles.contenido}>
                <h1>SOMOS UN EQUIPO UNIDO, CON LA CONFIANZA EN SI MISMO PARA LOGRAR SUPERAR GRANDES DESAFÍOS.</h1>
                <h3>No existen imposibles, solo obstáculos a superar. ¡Podemos lograr lo inimaginable, la experiencia y el ingenio están de nuestro lado!</h3>
            </div>
        </div>
    )
}

export default ElegirnosSegundaVista