import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import navbar_español from './translations/es/navbar.json'
import buttonBack_español from './translations/es/buttonBack.json'
import navbar_ingles from './translations/en/navbar.json'
import buttonBack_ingles from './translations/en/buttonBack.json'



// const getFecha = (fecha) => {
//   return (new Date().toLocaleString('es-AR', {
//   timeZone: 'America/Argentina/Buenos_Aires'
// }))
// };

// export var fecha = getFecha()


i18next.init({
  interpolation: {escapeValue: false},
  lng: "es" , /*Lenguaje a inicializar*/
  resources: {
    es: {
      navbar: navbar_español,
      buttonBack: buttonBack_español
    },
    en: {
      navbar: navbar_ingles,
      buttonBack: buttonBack_ingles
    }
  }
})


ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
    <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);