import React from 'react'
import styles from './styles.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { facebookUrl, instagramUrl } from '../../api/urls'


const Barra_Redes = () => {
    return (
        <div className={styles.Barra_Redes_container}>
            <div className={styles.contenido}>
                <FontAwesomeIcon icon={faFacebook} className={styles.icono_redes} onClick={() => window.open(facebookUrl, '_blank')} />
                <FontAwesomeIcon icon={faInstagram} className={styles.icono_redes} onClick={() => window.open(instagramUrl, '_blank')} />
            </div>
        </div>
    )
}

export default Barra_Redes